import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
// import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
// import LandingPage from "views/LandingPage/TournamentLivePage";
// import LandingPage from "views/LandingPage/TournamentEndPage";
// import Tournament2020Archive from "views/LandingPage/Tournament2020Archive.js";
// import ProfilePage from "views/ProfilePage/ProfilePage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
      {/* <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} /> */}
      {/* <Route path="/style-page" component={Components} /> */}
      <Route exact path="/" component={LandingPage} />
      {/* <Route path="/" component={TournamentEndPage} /> */}
      {/* <Route path="/2020" component={Tournament2020Archive} /> */}
  </Router>,
  document.getElementById("root")
);
