import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import matchupstyles from "assets/css/matchup.css";

const useStyles = makeStyles(styles);

export default function BracketSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="BracketSection">
      <div class="wrapper">
        <h1>Round 1 Matchup</h1>
        <div class="divider">
          <span>
            Round 1 - Score 2-2
          </span>
        </div>
        <ol start="1">
          <li>
            <span>JDrip Drip vs J Wagner</span>
            <span>Singles (Green vs Red)</span>
            <span>Final 7-6 | 4 NTRP</span>
          </li>
          <li>
            <span>Erika vs Aline</span>
            <span>Singles (Green vs Red)</span>
            <span>Final 6-0 | 4 NTRP</span>
          </li>
          <li>
            <span>Ian M. & Wai Lam vs Brandon H. & Julie Silverman</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 0-6 | 7 NTRP</span>
          </li>
          <li>
            <span>Denny Z. & Luiz P. vs Johnny Sze & Chris Kelly</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 1-6 | 7 NTRP</span>
          </li>
        </ol>

        <h1>Round 2 Matchup</h1>
        <div class="divider">
          <span>
            Round 2 - Score 4-6
          </span>
        </div>
        <ol start="1">
          <li>
            <span>Warid Jamal vs Jeff Li</span>
            <span>Singles (Green vs Red)</span>
            <span>Final 4-6 | 4 NTRP</span>
          </li>
          <li>
            <span>Brian McCombs vs Arnel</span>
            <span>Singles (Green vs Red)</span>
            <span>Final 4-6 | 4 NTRP</span>
          </li>
          <li>
            <span>Cheryl S. & Luiz P. vs Liz B. & Brandon H.</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 2-6 | 7 NTRP</span>
          </li>
          <li>
            <span>Nikzad & Zachary vs Mike A. & Simon</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 7-6 | 8 NTRP</span>
          </li>
          <li>
            <span>Kevin Moy & Paige vs Dave Song & Victoria</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 6-4 | 7.5 NTRP</span>
          </li>
        </ol>

        <h1>Round 3 Matchup</h1>
        <div class="divider">
          <span>
            Round 3 - 13-15
          </span>
        </div>
        <ol start="1">
          <li>
            <span>Kevin Moy vs Chris Kelly</span>
            <span>Singles (Green vs Red)</span>
            <span>Final 6-1 | 4 NTRP</span>
          </li>
          <li>
            <span>Warid Jamal & Brian McCombs vs Mike A. & Joe</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 5-7 | 7.5 NTRP</span>
          </li>
          <li>
            <span>Wai Lam & JDrip Drip vs Aline & Dave Song</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 6-7 <b>Decider</b> | 7.5 NTRP</span>
          </li>
          <li>
            <span>Brian Li & Kelsey vs J Wagner & Maitane</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 6-1 | 8.5 NTRP</span>
          </li>
          <li>
            <span>Cheryl S. & Erika vs Victoria & Julie Silverman</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 7-6 | 7 NTRP</span>
          </li>
          <li>
            <span>Ian M. and Paige vs Brandon H. & Liz B.</span>
            <span>Doubles (Green vs Red)</span>
            <span>Final 4-6 | 7.5 NTRP</span>
          </li>
        </ol>
        <div class="divider">
          <span>
            Congratulations to the Red team!{" "}
          </span>
          <br></br>
          <span>Thank you all for joining us this year!</span>
        </div>
      </div>
    </div>
  );
}
