import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FBI from "@material-ui/icons/SportsTennis";
import Deuce from "@material-ui/icons/CallSplit";
import OneSet from "@material-ui/icons/LooksOne";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Slide from "@material-ui/core/Slide";

import "assets/css/customHr.css";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function RulesSection() {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  return (
    <div className={classes.section} id="RulesSection">
      <GridContainer justify="center">
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/dNqFw3Xjfv4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Tournament Rules</h2>
          <h4 className={classes.description}>
            Simon{"'"}s Cup will be team based this year. <br></br>It will be a single day tournament broken out into 3 rounds.<br></br>
            Team with the most points win.
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="No First Ball In (aka no FBI)"
              description="To keep things fair and moving along, please warm up services before tournament play. There will be a 10 minute warm up with your opponent."
              icon={FBI}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="No-ad Scoring (aka no deuces)"
              description="No-ad scoring is a scoring method in which the first player to reach four points wins the game. Therefore, if the game is tied at deuce, the next player to win a point wins the game. The player receiving will choose which side to receive the serve."
              icon={Deuce}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="One Tie-Break Set"
              description="A tie-break set is played until a player has won at least 6 games. When the score is tied at 6–6, a tie-break game (or tiebreaker) is played. Typically, the tie-break game continues until one side has won seven points with a margin of two or more points."
              icon={OneSet}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
      <Button color="primary" block onClick={() => setClassicModal(true)}>
        <LibraryBooks className={classes.icon} />
        Tournament Format
      </Button>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Tournament Format</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
        <div class="container">
          <p><b>How Scoring Works</b></p>
          <p><b>Tournament is split into 3 rounds.</b></p>
          <p>- Each matchup plays a regulation set, first to 6 games. Each set will earn points for the round.</p>
          <hr></hr>
          <p><b>Round 1</b></p>
          <p>- Each set won during this round will earn 1 point for the winning team.</p>
          <p></p>
          <hr></hr>
          <p><b>Round 2</b></p>
          <p>- Each set won during this round will earn 2 points for the winning team.</p>
          <p></p>
          <hr></hr>
          <p><b>Round 3</b></p>
          <p>- Each set won during this round will earn 3 points for the winning team.</p>
          <p></p>
        </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setClassicModal(false)} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
