import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import Badge from "components/Badge/Badge.js";
import menshirt from "assets/img/faces/unisex-shirt-2024.png";
import menshirt2 from "assets/img/faces/unisex-shirt2-2024.png";
import menguide from "assets/img/faces/men-2021-shirtguide-min.png";
import womenshirt from "assets/img/faces/women-2021-shirt-min.png";
import womenguide from "assets/img/faces/women-2021-shirtguide-min.png";

const useStyles = makeStyles(styles);

export default function ShirtSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section} id="ShirtSection">
      <h2 className={classes.title}>Simon{"'"}s Cup T-Shirt</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={16}>
            <Card plain>
              <GridItem xs={12} sm={12} md={35} className={classes.itemGrid}>
                <img width="450px" height="365px" src={menshirt} alt="..." className={imageClasses} />
                <img width="450px" height="365px" src={menshirt2} alt="..." className={imageClasses} />
                <img width="450px" src={menguide} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Unisex{"'"} T-Shirt
                <br />
              </h4>
              <h5>Description</h5>
              <CardBody>
                <p className={classes.description}>
                  <Badge color="info">$30</Badge> for 1 moisture-wicking polyester Maroon.
                </p>
                <p className={classes.description}>
                  The highly breathable fabric of this Maroon shirt is
                  lightweight so it won{"'"}t weigh you down in the heat and
                  humidity. Shirts help pay for the prizes and equipment.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
