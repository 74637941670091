/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      { <ListItem className={classes.listItem}>
        <Tooltip
          id="tournament-twitter"
          title="Tournament Rules"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="#RulesSection"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa fa-gavel"} />
          </Button>
        </Tooltip>
      </ListItem> }
      {/* { <ListItem className={classes.listItem}>
        <Tooltip
          id="tournament-shirt"
          title="T-Shirt Description"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#ShirtSection"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa fa-suitcase"} />
          </Button>
        </Tooltip>
      </ListItem> } */}
      {/* { <ListItem className={classes.listItem}>
        <Tooltip
          id="sign-up"
          title="Tournament Sign Up"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#SignupSection"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa fa-paw"} />
          </Button>
        </Tooltip>
      </ListItem> } */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="tournament-standings"
          title="Final Standings"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://challonge.com/t1jpuj51/standings"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fas fa-boxes"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="tournament-bracket"
          title="Bracket"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#BracketSection"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa fa-trophy"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="tournament-bracket"
          title="Bracket"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="#BracketSection"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fa fa-trophy"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
