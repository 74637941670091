import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function SignupSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="SignupSection">
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Sign Up For Simon{"'"}s Cup</h2>
          <h4 className={classes.description}>Simon{"'"}s Cup is FREE!</h4>
          <h4 className={classes.description}>
            Please fill out the form below to sign up for the tournament.
          </h4>
          <div align="center">
            <iframe
              id="signup-form"
              title="Simon's Cup Signup Form"
              src="https://docs.google.com/forms/d/1O3NrHqlW1l075_gBc8IDBGuaMA_NwH0Cygw-PZ8dHig/viewform?embedded=true"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="1900"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
